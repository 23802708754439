import React, { useState, useEffect } from 'react';
//
import './LogosPanel.scss';

export default function LogosPanel({ logos }) {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const checkScreenSize = () => {
      if (
        typeof window !== 'undefined' &&
        window.matchMedia('(min-width: 960px)').matches
      )
        setIsLargeScreen(true);
      else setIsLargeScreen(false);
    };
    checkScreenSize();

    window.addEventListener('orientationchange', checkScreenSize);

    const interval = setInterval(() => {
      setPosition((position + 1) % logos.length);
    }, 1500);

    return () => {
      clearInterval(interval);
      window.removeEventListener('orientationchange', checkScreenSize);
    };
  }, [position]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLogos = () => {
    return logos.map((item, index) => {
      const { desktopImageWidth, mobileImageWidth } = item.fields;

      return (
        <div
          key={index}
          className={`logo-container ${index === position ? 'reveal' : ''}`}
          style={{
            width: isLargeScreen ? desktopImageWidth : mobileImageWidth,
          }}
        >
          <img
            src={item.fields.logoImage.fields.image.fields.file.url}
            alt={item.fields.logoImage.fields.altText}
          />
        </div>
      );
    });
  };

  return <div className='LogosPanel'>{renderLogos()}</div>;
}
