import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import Markdown from 'react-markdown';
import { Link } from 'gatsby';
//
import './HomeContentItem.scss';
import { STORE_CONSTANTS, Store } from '../../Store';
import CountUpPanel from './CountUpPanel';
import LogosPanel from './LogosPanel';
import YoutubePanel from './YoutubePanel';

const EVENT_TYPES = {
  ENTER: 'enter',
  LEAVE: 'leave',
  END: 'end',
};

export default function HomeContentItem({ index, componentData, isHeroItem }) {
  const { dispatch } = useContext(Store);

  const [reveal, setReveal] = useState(false);
  const [zIndex, setZIndex] = useState(0);
  const textContainerRef = useRef();
  const [isLargeScreen, setIsLargeScreen] = useState(null);

  const [heroReveal, setHeroReveal] = useState(false);
  const imageRef = useRef();
  const videoRef = useRef();

  const parseAssetData = () => {
    const parsedData = {
      assetType: null,
      mobileImage: null,
      desktopImage: null,
      altText: null,
      mobilePoster: null,
      mobileVideo: null,
      mobileObjectPosition: null,
      desktopPoster: null,
      desktopVideo: null,
      desktopObjectPosition: null,
      videoShouldLoop: null,
    };

    const assetPanelData = _.get(componentData, 'fields.assetPanel', null);
    const mobileAssetData = _.get(assetPanelData, 'fields.mobileAsset', null);
    const desktopAssetData = _.get(assetPanelData, 'fields.desktopAsset', null);
    const type = _.get(desktopAssetData, 'sys.contentType.sys.id', null);

    if (!type || !mobileAssetData || !desktopAssetData) return parsedData;

    if (type === 'elementImage') {
      parsedData.assetType = 'elementImage';

      const mobileImage = _.get(
        mobileAssetData,
        'fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      );
      const desktopImage = _.get(
        desktopAssetData,
        'fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      );
      const altText = _.get(
        desktopAssetData,
        'fields.altText',
        'PLACEHOLDER ALT TEXT'
      );

      parsedData.mobileImage = mobileImage;
      parsedData.mobileObjectPosition = 'center center';
      parsedData.desktopImage = desktopImage;
      parsedData.desktopObjectPosition = 'center center';
      parsedData.altText = altText;
    } else if (type === 'elementAmbientVideo') {
      parsedData.assetType = 'elementAmbientVideo';

      const mobileAssetData = _.get(assetPanelData, 'fields.mobileAsset', null);
      const mobilePreviewImage = _.get(
        mobileAssetData,
        'fields.previewImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      );
      const mobileVideo = _.get(
        mobileAssetData,
        'fields.video.fields.file.url',
        null
      );
      const mobileObjectPositionX = _.get(
        mobileAssetData,
        'fields.cropAnchorX',
        'center'
      );
      const mobileObjectPositionY = _.get(
        mobileAssetData,
        'fields.cropAnchorY',
        'top'
      );
      const desktopAssetData = _.get(
        assetPanelData,
        'fields.desktopAsset',
        null
      );
      const desktopPreviewImage = _.get(
        desktopAssetData,
        'fields.previewImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      );
      const desktopVideo = _.get(
        desktopAssetData,
        'fields.video.fields.file.url',
        null
      );
      const desktopObjectPositionX = _.get(
        desktopAssetData,
        'fields.cropAnchorX',
        'center'
      );
      const desktopObjectPositionY = _.get(
        desktopAssetData,
        'fields.cropAnchorY',
        'top'
      );

      const videoShouldLoop = _.get(
        desktopAssetData,
        'fields.shouldThisVideoLoop',
        false
      );

      parsedData.mobilePoster = mobilePreviewImage;
      parsedData.mobileVideo = mobileVideo;
      parsedData.mobileObjectPosition = `${mobileObjectPositionX.toLowerCase()} ${mobileObjectPositionY.toLowerCase()}`;
      parsedData.desktopPoster = desktopPreviewImage;
      parsedData.desktopVideo = desktopVideo;
      parsedData.desktopObjectPosition = `${desktopObjectPositionX.toLowerCase()} ${desktopObjectPositionY.toLowerCase()}`;
      parsedData.videoShouldLoop = videoShouldLoop;
    }

    return parsedData;
  };

  const {
    assetType,
    mobileImage,
    desktopImage,
    altText,
    mobilePoster,
    mobileVideo,
    mobileObjectPosition,
    desktopPoster,
    desktopVideo,
    desktopObjectPosition,
    videoShouldLoop,
  } = parseAssetData();

  useEffect(() => {
    if (isHeroItem) {
      setTimeout(() => {
        if (assetType === 'elementImage') {
          if (imageRef.current.complete) setHeroReveal(true);
          else
            imageRef.current.addEventListener('load', () =>
              setHeroReveal(true)
            );
        } else if (assetType === 'elementAmbientVideo') {
          videoRef.current.addEventListener('canplay', () =>
            setHeroReveal(true)
          );
          videoRef.current.load();
        }
      }, 400);
    }
  }, [isHeroItem, assetType]);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.matchMedia('(min-width: 768px) and (min-height: 500px)').matches
    ) {
      return init();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const init = () => {
    let controller, scene;

    const cb = e => {
      if (e.type === EVENT_TYPES.ENTER) {
        setZIndex(index);
        setReveal(true);

        dispatch({
          type: STORE_CONSTANTS.ACTIONS.SET_HOME_ACTIVE_INDEX,
          payload: index,
        });
      } else if (e.type === EVENT_TYPES.LEAVE) {
        setReveal(false);

        setTimeout(() => setZIndex(0), 500);

        dispatch({
          type: STORE_CONSTANTS.ACTIONS.SET_HOME_ACTIVE_INDEX,
          payload: index - 1,
        });
      }
    };

    // Updated to avoid ScrollMagic being imported at build time, causing a Gatsby SSR error
    import('scrollmagic').then(ScrollMagic => {
      controller = new ScrollMagic.Controller();

      scene = new ScrollMagic.Scene({
        triggerElement: textContainerRef.current,
      }).on('enter leave', cb);

      scene.addTo(controller);

      return () => {
        scene.off('enter leave', cb);
        controller = controller.destroy();
      };
    });
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setTimeout(() => {
        if (
          typeof window !== 'undefined' &&
          window.matchMedia('(min-width: 960px)').matches
        ) {
          setIsLargeScreen(true);
        } else {
          setIsLargeScreen(false);
        }
      }, 250);
    };
    checkScreenSize();

    typeof window !== 'undefined' &&
      window.addEventListener('orientationchange', checkScreenSize);

    return () => {
      window.removeEventListener('orientationchange', checkScreenSize);
    };
  }, [setIsLargeScreen]);

  const renderStandardAssettPanel = () => {
    if (!assetType) return;

    if (assetType === 'elementImage') {
      if (!mobileImage || !desktopImage || !altText) return;

      return (
        <div className='asset-image'>
          <picture>
            <source
              media='(max-width: 767px)'
              srcSet={`${mobileImage}`}
              sizes='100vw'
            />
            <img
              src={desktopImage}
              alt={altText}
              ref={imageRef}
              style={{
                objectPosition: isLargeScreen
                  ? desktopObjectPosition
                  : mobileObjectPosition,
              }}
            />
          </picture>
        </div>
      );
    } else if (assetType === 'elementAmbientVideo') {
      if (
        !mobilePoster ||
        !mobileVideo ||
        !desktopPoster ||
        !desktopVideo ||
        isLargeScreen === null
      )
        return;

      return (
        <div className='asset-video'>
          <video
            src={isLargeScreen ? desktopVideo : mobileVideo}
            poster={isLargeScreen ? desktopPoster : mobilePoster}
            playsInline
            muted
            loop={videoShouldLoop}
            autoPlay
            preload={`${isHeroItem ? 'none' : 'auto'}`}
            ref={videoRef}
            style={{
              objectPosition: isLargeScreen
                ? desktopObjectPosition
                : mobileObjectPosition,
            }}
          />
        </div>
      );
    }
  };

  const handleYoutubePanelFocus = () => {
    textContainerRef.current.scrollIntoView();
  };

  const renderAssetPanel = () => {
    const assetPanelData = _.get(componentData, 'fields.assetPanel', null);
    const type = _.get(assetPanelData, 'sys.contentType.sys.id', null);

    if (!assetPanelData || !type) return;

    switch (type) {
      case 'componentHomeContentItemAssetPanel':
        return renderStandardAssettPanel(assetPanelData);

      case 'componentHomeContentItemCountUpPanel':
        return (
          <CountUpPanel {...assetPanelData.fields} startCountUp={reveal} />
        );

      case 'componentHomeContentItemLogosPanel':
        return <LogosPanel {...assetPanelData.fields} />;

      case 'componentHomeContentItemYoutubePanel':
        return (
          <YoutubePanel
            handleYoutubePanelFocus={handleYoutubePanelFocus}
            {...assetPanelData.fields}
          />
        );

      default:
        return null;
    }
  };

  const renderTextPanel = () => {
    const textPanelData = _.get(componentData, 'fields.textPanel');

    if (!textPanelData) return;

    const isQuote = _.get(textPanelData, 'fields.isQuote', false);
    const primaryTextData = _.get(textPanelData, 'fields.primaryText', null);
    const secondaryTextData = _.get(
      textPanelData,
      'fields.secondaryText',
      null
    );
    const button = _.get(textPanelData, 'fields.button', null);

    if (!primaryTextData) return;

    const primaryText = _.get(
      primaryTextData,
      'fields.text',
      'PRIMARY TEXT MISSING'
    );
    const primaryTextSize = _.get(primaryTextData, 'fields.textSize', 'Medium');

    const secondaryText = _.get(
      secondaryTextData,
      'fields.text',
      'SECONDARY TEXT MISSING'
    );
    const secondaryTextSize = _.get(
      secondaryTextData,
      'fields.textSize',
      'Medium'
    );

    return (
      <React.Fragment>
        {isQuote && <div className='quotation-mark'>“</div>}
        <div className={`primary-text text-${primaryTextSize}`}>
          <Markdown children={primaryText} />
        </div>
        {secondaryTextData && (
          <div className={`secondary-text text-${secondaryTextSize}`}>
            <Markdown children={secondaryText} />
          </div>
        )}
        {button ? (
          button.fields.opensNewWindow ? (
            <a
              className='button'
              href={button.fields.link}
              target='_blank'
              rel='noopener noreferrer'
            >
              {button.fields.labelText}
            </a>
          ) : (
            <Link className='button' to={button.fields.link}>
              {button.fields.labelText}
            </Link>
          )
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div
      className={`HomeContentItem ${isHeroItem ? 'hero-item' : ''} ${
        reveal ? 'reveal' : ''
      } ${heroReveal ? 'hero-reveal' : ''}`}
    >
      <div className='asset-panel' style={{ zIndex: zIndex }}>
        <div className='asset-wrapper'>{renderAssetPanel()}</div>
        <div className='asset-background'></div>
      </div>
      {isHeroItem ? (
        <h1 className='text-panel' ref={textContainerRef}>
          {renderTextPanel()}
        </h1>
      ) : (
        <div className='text-panel' ref={textContainerRef}>
          {renderTextPanel()}
        </div>
      )}
    </div>
  );
}
