import React, { useEffect, useContext } from 'react';
//
import './HomeBody.scss';
import { STORE_CONSTANTS, Store } from '../../Store';
import HomeContentItem from './HomeContentItem';
import ChartItem from './ChartItem';

export default function HomeBody({ bodyComponents }) {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({
      type: STORE_CONSTANTS.ACTIONS.SET_HOME_ACTIVE_INDEX,
      payload: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComponents = () => {
    const components = [];

    bodyComponents.forEach((componentData, i) => {
      switch (componentData.sys.contentType.sys.id) {
        case 'componentHomeContentItem':
          components.push(
            <HomeContentItem
              key={i}
              index={i + 1}
              componentData={componentData}
              isHeroItem={i === 0}
            />
          );

          break;

        case 'componentHomeContentItemChartPanel':
          components.push(
            <ChartItem key={i} index={i + 1} componentData={componentData} />
          );

          break;

        default:
          break;
      }
    });

    return components;
  };

  return <div className='HomeBody'>{renderComponents()}</div>;
}
