import React from 'react';
//
import { StateProvider } from '../Store';
import Head from './../components/head/Head';
import TopNav from './../components/topNav/TopNav';
import Footer from './../components/footer/Footer';
import YoutubeOverlay from './../components/youtubeOverlay/YoutubeOverlay';
import HomeBody from './../components/homeBody/HomeBody';
import ThemeWrapper from './ThemeWrapper';

export default function HomePage({
  pageContext: { topNavData, footerData, bodyComponents, seoMetadata },
}) {
  return (
    <div className='HomePage'>
      <StateProvider>
        <Head {...seoMetadata} />
        <ThemeWrapper pageStyle='home'>
          <TopNav topNavData={topNavData} />
          <main>
            <HomeBody bodyComponents={bodyComponents} />
          </main>
          <Footer footerData={footerData} />
          <YoutubeOverlay />
        </ThemeWrapper>
      </StateProvider>
    </div>
  );
}
