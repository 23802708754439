import React, { useState, useRef, useEffect } from 'react';
import { Chart } from 'chart.js';
//
import './ChartItem.scss';

const EVENT_TYPES = {
  ENTER: 'enter',
  LEAVE: 'leave',
};

export default function ChartItem({ index, componentData }) {
  const [reveal, setReveal] = useState(false);
  const [zIndex, setZIndex] = useState(0);

  const textContainerRef = useRef();
  const statRefs = useRef([]);

  const canvasRef = useRef();
  const chartRef = useRef();

  const [currentSmallScreenPosition, setCurrentSmallScreenPosition] =
    useState(0);
  const [currentLargeScreenPosition, setCurrentLargeScreenPosition] =
    useState(-1);

  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.matchMedia('(min-width: 768px) and (min-height: 500px)').matches
    ) {
      return init();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const init = () => {
    // Updated to avoid ScrollMagic being imported at build time, causing a Gatsby SSR error
    import('scrollmagic').then(ScrollMagic => {
      let controller, revealScene;

      controller = new ScrollMagic.Controller();

      revealScene = new ScrollMagic.Scene({
        triggerElement: textContainerRef.current,
      }).on('enter leave', e => {
        if (e.type === EVENT_TYPES.ENTER) {
          setZIndex(index);
          setReveal(true);
        } else if (e.type === EVENT_TYPES.LEAVE) {
          setReveal(false);

          setTimeout(() => setZIndex(0), 500);
        }
      });

      revealScene.addTo(controller);

      const statScenes = [];

      componentData.fields.stats.forEach((stat, i) => {
        const statScene = new ScrollMagic.Scene({
          triggerElement: statRefs.current[i],
        }).on('enter leave', e => {
          let isLargeScreen = window.matchMedia('(min-width: 960px)').matches;

          if (e.type === EVENT_TYPES.ENTER) {
            setCurrentLargeScreenPosition(i);

            isLargeScreen &&
              updateChart(componentData.fields.stats[i].fields.number);
          } else if (e.type === EVENT_TYPES.LEAVE) {
            setCurrentLargeScreenPosition(i - 1);

            if (i - 1 >= 0)
              isLargeScreen &&
                updateChart(componentData.fields.stats[i - 1].fields.number);
            else updateChart(0);
          }
        });

        statScene.addTo(controller);

        statScenes.push(statScene);
      });

      return () => {
        revealScene.off('enter leave');

        statScenes.forEach(statScene => statScene.off('enter'));

        controller = controller.destroy();
      };
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const ctx = canvasRef.current.getContext('2d');

      chartRef.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [
            {
              backgroundColor: ['#EE0000', '#d8dada'],
              borderWidth: 0,
              data: [0, 100],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 1,
          cutoutPercentage: 64,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const interval = setInterval(() => {
        if (window.matchMedia('(max-width: 959px)').matches) {
          updateChart(
            componentData.fields.stats[currentSmallScreenPosition].fields.number
          );

          setCurrentSmallScreenPosition(
            (currentSmallScreenPosition + 1) % componentData.fields.stats.length
          );
        }
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [componentData, currentSmallScreenPosition]);

  const updateChart = value => {
    chartRef.current.data.datasets[0].data[0] = value;
    chartRef.current.data.datasets[0].data[1] = 100 - value;

    chartRef.current.update();

    setTimeout(() => setCurrentPercentage(value), 250);
  };

  return (
    <div className={`ChartItem ${reveal ? 'reveal' : ''}`}>
      <div className='asset-panel' style={{ zIndex: zIndex }}>
        <div className='asset-wrapper'>
          <div className={`percentage ${currentPercentage > 0 ? 'show' : ''}`}>
            {currentPercentage}
            <sup>%</sup>
          </div>
          <div className='canvas-container'>
            <canvas className='chart-canvas' ref={canvasRef}></canvas>
          </div>
        </div>
        <div className='footnote-desktop'>
          *Based on full-time roles offered to classes 1.0 - 3.0
        </div>
      </div>
      <div className='text-panel' ref={textContainerRef}>
        <div className='footnote-mobile'>
          *Based on full-time roles offered to classes 1.0 - 3.0
        </div>
        <div className={`small-screen-text`}>
          {componentData.fields.stats.map((stat, i) => {
            return (
              <div
                key={i}
                className={`stat-text ${
                  i === currentSmallScreenPosition ? 'reveal' : ''
                }`}
              >
                {stat.fields.name}
              </div>
            );
          })}
        </div>
        <div className='large-screen-text'>
          {componentData.fields.stats.map((stat, i) => {
            return (
              <div
                key={i}
                ref={el => (statRefs.current[i] = el)}
                className={`stat-text ${
                  i === currentLargeScreenPosition ? 'active' : ''
                }`}
              >
                {stat.fields.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
