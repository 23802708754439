import React, { useContext } from 'react';
import _ from 'lodash';
//
import './YoutubePanel.scss';
import { STORE_CONSTANTS, Store } from './../../Store';

export default function YoutubePanel({
  youtubeVideo,
  mobilePreviewImage,
  desktopPreviewImage,
  handleYoutubePanelFocus,
}) {
  const { dispatch } = useContext(Store);

  const handleClickOpenOverlay = () => {
    dispatch({
      type: STORE_CONSTANTS.ACTIONS.SET_CURRENT_YOUTUBE_OVERLAY_VIDEO_ID,
      payload: _.get(youtubeVideo, 'fields.videoID', null),
    });

    dispatch({
      type: STORE_CONSTANTS.ACTIONS.SET_DISPLAY_YOUTUBE_OVERLAY,
      payload: true,
    });
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleClickOpenOverlay();
    }
  };

  const handleFocus = () => {
    handleYoutubePanelFocus();
  };

  return (
    <div className='YoutubePanel'>
      <div
        className='asset-image'
        onClick={handleClickOpenOverlay}
        onKeyDown={e => handleKeyDown(e)}
        role='button'
        tabIndex={0}
        onFocus={handleFocus}
      >
        <picture>
          <source
            media='(max-width: 767px)'
            srcSet={`${mobilePreviewImage.fields.image.fields.file.url}`}
            sizes='100vw'
          />
          <img
            src={desktopPreviewImage.fields.image.fields.file.url}
            alt={desktopPreviewImage.fields.altText}
          />
        </picture>
        <div className='play-button'>
          <img src='/img/play.svg' alt='Play' />
        </div>
      </div>
    </div>
  );
}
