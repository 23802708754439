import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useCountUp } from 'react-countup';
//
import './CountUpPanel.scss';

export default function CountUpPanel({
  startingValue,
  endingValue,
  duration,
  startCountUp,
}) {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.matchMedia('(min-width: 960px)').matches
    )
      setIsLargeScreen(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (startCountUp && !hasStarted)
      setTimeout(() => {
        setHasStarted(true);

        start();
      }, 500);
  }, [startCountUp]); // eslint-disable-line react-hooks/exhaustive-deps

  const [containerRef, isInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (isInView && !isLargeScreen && !hasStarted) {
      setHasStarted(true);

      start();
    }
  }, [isInView]); // eslint-disable-line react-hooks/exhaustive-deps

  const { countUp, start } = useCountUp({
    start: startingValue,
    end: endingValue,
    duration: duration,
    startOnMount: false,
    useEasing: false,
    formattingFn: value => {
      const numbers = String(value).split('');

      if (numbers.length === 1)
        return (
          <span className='count-up-number second-digit'>{numbers[0]}</span>
        );
      if (numbers.length === 2)
        return (
          <React.Fragment>
            <span className='count-up-number first-digit'>{numbers[0]}</span>
            <span className='count-up-number second-digit'>{numbers[1]}</span>
          </React.Fragment>
        );
    },
  });

  return (
    <div className='CountUpPanel' ref={containerRef}>
      {countUp}
    </div>
  );
}
